import React from "react";
import { Link } from "gatsby";
import { IoLogoLinkedin } from "react-icons/io5";
import { Grid, Text, Flex, Box, Label } from "../../utils/system";
import Logo from "../Logo";

const Footer = () => (
  <Flex p={[4, 5]} bg="black">
    <Grid gridTemplateColumns={["1fr", "2fr 1fr 1fr"]} pb={7} color="white">
      <Logo color="white" />
      <Box mt={[4, 0]}>
        <Label fontSize={2} mt={0}>
          NAVIGATION
        </Label>
        <Link to="/about">
          <Text>About Us</Text>
        </Link>
        <Link to="/case-studies">
          <Text>Case Studies</Text>
        </Link>
        <Link to="/insights">
          <Text>Insights</Text>
        </Link>
        <Link to="/events">
          <Text>Events</Text>
        </Link>
      </Box>
      <Box mt={[4, 0]}>
        <Label fontSize={2} mt={0}>
          CONTACT
        </Label>

        <Text mb={0}>+44 (0) 7525 722 333</Text>
        <a
          href="https://www.linkedin.com/company/the-creo-company/"
          target="_blank"
          rel="noreferrer"
        >
          <Flex justifyContent="flex-start">
            <IoLogoLinkedin size={20} />
            <Text ml={2}>The Creo Company</Text>
          </Flex>
        </a>
      </Box>
    </Grid>
  </Flex>
);

export default Footer;
