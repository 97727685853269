import styled from "styled-components";
import {
  space,
  color,
  flexbox,
  layout,
  grid,
  typography,
  border,
  position,
} from "styled-system";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import theme from "./theme";

export const Box = styled.div(color, layout, border, space, position);

export const Flex = styled.div(
  {
    display: "flex",
    // width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  color,
  position,
  flexbox,
  layout,
  space,
  border
);

export const Grid = styled.div(
  { display: "grid", width: "100%" },
  color,
  grid,
  layout,
  space
);

export const Title = styled.h1(
  {
    fontFamily: theme.fonts.heading,
  },
  typography,
  color,
  layout,
  space,
  position
);

export const Label = styled.h3(
  {
    fontFamily: theme.fonts.subHeading,
    marginBottom: 0,
  },
  typography,
  color,
  layout,
  space,
  position
);

export const Text = styled.p(
  {
    fontFamily: theme.fonts.body,
  },
  typography,
  color,
  layout,
  space
);

export const Item = styled(Link)(
  {
    fontFamily: theme.fonts.subHeading,
    textTransform: "uppercase",
    color: "black",
    textDecoration: "none",
    marginRight: 30,
    fontWeight: 400,
    "&:hover": {
      // color: "red",
      textDecoration: "underline",
    },
  },
  typography,
  color,
  layout,
  space,
  border
);

export const Line = styled.hr(
  { border: "1px solid grey" },
  color,
  layout,
  space,
  border
);

export const Image = styled(GatsbyImage)(space, layout, position);
