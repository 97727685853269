import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "../utils/theme";

import "../css/main.css";

import Menu from "./Menu";
import Footer from "./Footer";

const Layout = ({ dark, children }) => (
  <ThemeProvider theme={theme}>
    <Menu dark={dark} />
    {children}
    <Footer />
  </ThemeProvider>
);

export default Layout;
