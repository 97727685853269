import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  color,
  position,
  flexbox,
  layout,
  space,
  border,
  typography,
} from "styled-system";
import { Flex, Item } from "../../utils/system";
import Logo from "../Logo";
import { motion } from "framer-motion";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

const Menu = () => {
  const [open, toggle] = useState(false);

  const variants = {
    open: { x: 0 },
    closed: { x: "+100vw" },
  };
  return (
    <Flex justifyContent="space-between" p={[4, 5]}>
      <Link to="/">
        <Logo size={200} />
      </Link>
      <Flex display={["none", "none", "flex"]}>
        <Item to="/about">About Us</Item>
        <Item to="/case-studies">Case Studies</Item>
        {/* <Item to="/products">Products & Services</Item> */}
        <Item to="/insights">Insights</Item>
        <Item to="/events">Events</Item>
      </Flex>
      <Flex
        bg="black"
        display={["flex", "flex", "none"]}
        width={40}
        height={40}
        color="white"
        borderRadius={20}
        onClick={() => toggle(!open)}
        zIndex={2}
      >
        {open ? <IoCloseOutline size={25} /> : <IoMenuOutline size={25} />}
      </Flex>

      <Slide
        zIndex={1}
        animate={open ? "open" : "closed"}
        variants={variants}
        transition={{ ease: [0.19, 1.0, 0.22, 1.0], duration: 0.8 }}
        position="fixed"
        initial={false}
        top={0}
        left={0}
        display={["flex", "flex", "none"]}
        width="100vw"
        height="100vh"
        bg="grey"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        m={0}
        fontSize={3}
      >
        <Item mr={0} my={1} to="/about">
          About Us
        </Item>
        <Item mr={0} my={1} to="/case-studies">
          Case Studies
        </Item>
        {/* <Item mr={0} my={1} to="/products">
          Products & Services
        </Item> */}
        <Item mr={0} my={1} to="/insights">
          Insights
        </Item>
        <Item mr={0} my={1} to="/events">
          Events
        </Item>
      </Slide>
    </Flex>
  );
};

export default Menu;

const Slide = styled(motion.div)(
  color,
  position,
  flexbox,
  layout,
  space,
  border,
  typography
);
