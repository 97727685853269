const theme = {
  colors: {
    yellow: "#F7D046",
    blue: "#0006b5",
    pink: "rgb(255, 34, 151)",
    teal: "#D2E4D9",
    navy: "#004175",
    beige: "#D8CC9E",
    orange: "#E27739",
    purple: "#836EAB",
    green: "#A2CEAC",
    grey: "#F2F2F2",
    dark: "#979797",
    black: "rgba(20,20,20,1)",
    white: "#ffffff",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fonts: {
    heading: '"Manrope-SemiBold", sans-serif',
    subHeading: '"Manrope-SemiBold", sans-serif',
    body: '"Satoshi-Medium", sans-serif',
  },
};

export default theme;
